<template lang="pug">
span(
  :class="{ filled: fillIcon, 'icon-light': size > 48 }"
  :style="{ 'font-size': size + sizeUnit }"
  aria-hidden="true"
  class="material-icon material-symbols-rounded"
)
  slot
</template>

<script>
// TODO: refactor to composition API
export default {
  props: {
    size: {
      type: Number,
      default: 24,
    },
    sizeUnit: {
      type: String,
      default: 'px',
    },
    fillIcon: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="postcss" scoped>
.material-icon {
  &.filled {
    font-variation-settings: 'FILL' 1;
    &.icon-thin {
      font-variation-settings: 'FILL' 1, 'wght' 100;
    }

    &.icon-light {
      font-variation-settings: 'FILL' 1, 'wght' 300;
    }
  }

  &.icon-thin {
    font-variation-settings: 'wght' 180;
  }

  &.icon-light {
    font-variation-settings: 'wght' 240;
  }
}
</style>
